import React from 'react';
import ProfileBar from '../profiles/Components/ProfileBar';
import Verification from '../assets/img/verification_icon.png';
import Union from '../assets/img/union.png';
import Reward from '../assets/img/reward_icon.png';
import Loader from '../components/Loader';
import Card from './components/Card';
import { useVerificationDetails } from './queries/actHooks';
import { useUserProfile } from '../profiles/queries/profileHooks';
import { getDateInDDMMYYYY } from '../constants/DateFunctions';
function Collection(props) {
  const { data: profile } = useUserProfile();

  const { data, isLoading } = useVerificationDetails({
    user_id: profile.message.sfa_id,
  });
  // console.log(data);
  return (
    <div>
      <ProfileBar head="Verification & Collection" act={true} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mx-6 mt-6  mb-5 flex flex-col gap-6">
          <Card
            heading="Physical Verification"
            status={data?.physical_verification?.status}
            contentHeader="Remarks"
            content={data?.physical_verification?.remark}
            icon={Verification}
            date={
              data?.physical_verification?.date
                ? getDateInDDMMYYYY(data?.physical_verification?.date)
                : '-'
            }
          />
          <Card
            heading="Accreditation"
            status={data?.accredition?.status}
            contentHeader="Remarks"
            content={data?.accredition?.remark}
            icon={Reward}
            date={data?.accredition?.date ? getDateInDDMMYYYY(data?.accredition?.date) : '-'}
          />
          <Card
            heading="Sports Kit"
            status={data?.sports_kit?.status}
            contentHeader="Remarks"
            content={data?.sports_kit?.remark}
            icon={Union}
            date={data?.sports_kit?.date ? getDateInDDMMYYYY(data?.sports_kit?.date) : '-'}
            sportSpecificKit={data?.sports_specific_kit}
          />
        </div>
      )}
    </div>
  );
}

export default Collection;
