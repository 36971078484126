import React from 'react';

export default function Card({
  heading,
  status,
  content,
  icon,
  contentHeader,
  isCoach,
  date,
  sportSpecificKit,
}) {
  return (
    <div
      data-cy="verification_collection_card"
      className="max-w-full bg-white px-6 py-4 font-bold  font-roboto rounded-lg"
    >
      <h1 data-cy="verification_collection_heading" className=" leading-6 text-tertiary-150">
        {heading}
      </h1>
      <div className="px-4 mt-4 pt-4 pb-5 border rounded-md border-fade-gray">
        <div
          className={` flex gap-7 items-center ${
            isCoach ? 'border-b pb-3' : ''
          }   border-fade-gray`}
        >
          <div>
            <img className="min-w-min" src={icon} alt="card icon" />
          </div>
          <div className="flex-grow">
            <div className="pb-2 border-b-0.5 border-fade-gray">
              <span data-cy="status_header" className="text-gray-450  text-xxs">
                Status
              </span>
              <p data-cy="status" className="text-small text-purple-550">
                {status}
              </p>
            </div>
            <div className="">
              <p data-cy="content_header" className="text-gray-450 text-xxs mt-2 mb-1.5">
                {contentHeader}
              </p>
              <span data-cy="content" className="text-pink-700  text-xs bg-fade-pink px-1 py-0.5">
                {content}
              </span>
              <div data-cy="date" className="text-xxs mt-2 text-gray-450">
                {date}
              </div>
            </div>
          </div>
        </div>
        {sportSpecificKit ? (
          <div className=" mt-3 pt-2 border-t-1 border-gray-150">
            <p className="text-small font-bold text-tertiary-150">Sports Specific Kit</p>
            <div className="flex items-center justify-between">
              <div>
                <span className="text-gray-450 text-xxs">Status</span>
                <p data-cy="sports_specific_kit_status" className="text-small text-purple-550 py-1">
                  {sportSpecificKit.status}
                </p>
              </div>
              <div>
                <p className="text-gray-450 text-xxs">Remarks</p>
                <span
                  data-cy="sports_specific_kit_remarks"
                  className="text-pink-700 text-xs bg-fade-pink  py-1"
                >
                  {sportSpecificKit.remark}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
